import React, { useEffect, createRef } from 'react';
import { SDK_APP_KEY, SDK_URL } from '../env';
import NavBar from './navbar';
import Banner from '../images/exchange-camp-banner.png';
import GiftIcon from '../images/exchange-gift-icon.svg';
import ExchangeFeature from '../images/exchange-feature.png';
import TickMark from '../images/referral-tick.svg';
import Stocks from '../images/stocks.svg';
import ProdService from '../images/prod-serv.png';
import SwapTrade from '../images/swap-trade.svg';
import Discord from '../images/exch-discord.svg';
import Medium from '../images/exch-medium.svg';
import Twitter from '../images/exch-twitter.svg';

/* eslint-disable */
const BulletPoints = ({ text, bold, margin }) => {
    return (
        <div className={`row t-follower ${margin === 1 ? 'mt-20' : ''}`}>
            <div className="col-1 affiliate-bullet ref2-points-bullet">
                <img
                    className="everest-follower-image"
                    src={TickMark}
                    alt="tick mark"
                />
            </div>
            <div className="col-11 ref2-points-text">
                <p
                    className={`${bold ? 'font-bold' : ''}`}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            </div>
        </div>
    );
};


const ExchangeRewards = ({ history }) => {
    const ref = createRef();

    useEffect(() => {
        let refValue;
        if (ref.current) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src =
                'https://s3.tradingview.com/external-embedding/embed-widget-screener.js';
            script.async = true;
            script.innerHTML = `{
                    "width": "100%",
                    "height": 700,
                    "defaultColumn": "overview",
                    "screener_type": "crypto_mkt",
                    "displayCurrency": "USD",
                    "colorTheme": "light",
                    "locale": "en"
            }`;
            ref.current.appendChild(script);
            refValue = ref.current;
        }

        return () => {
            if (refValue) {
                while (refValue.firstChild) {
                    refValue.removeChild(refValue.firstChild);
                }
            }
        };
    }, []);

    const initWidget = (mode) => {
        let sdkURL = SDK_URL;
        if(mode === 'trade') {
            sdkURL += "/trade"
        } else if(mode === 'signUp') {
            sdkURL += "/signup"
        } else if(mode === 'connect') {
            sdkURL += "/connect-wallet"
        }
        sdkURL += "?appKey="+SDK_APP_KEY+"&campaign=exchange"

        window.open(sdkURL, '_blank');
    };

    const markets = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="container-fluid px-0 overflow-hidden affiliate">
            <NavBar initWidget={initWidget} markets={markets} />
            <div className="exch-banner">
                <img
                    className="img-fluid exchange-banner"
                    src={Banner}
                    alt="banner"
                />
                <div className="exch-banner-text">
                    <h1 className="exch-banner-heading">
                        GET YOUR{' '}
                        <span className="exch-banner-reward">
                            $25
                        </span>{' '}
                        WELCOME GIFT
                    </h1>
                    <h2 className="exch-banner-heading2">
                        & Over <span className="exch-banner-reward2">$25,000</span> in Referral Bonuses
                    </h2>
                    <button
                        className="nav-exchange-signup-btn exch-banner-btn"
                        onClick={() => initWidget('signUp')}
                    >
                        Sign Up
                    </button>
                </div>
            </div>
            <div className="row exch-rewards">
                <div className="col-md-4">
                    <img
                        className="exch-gift-icon"
                        src={GiftIcon}
                        alt="gift"
                        width={25}
                    />
                    Get a <span className="exch-gift-clr">$25</span> Welcome
                    Gift
                </div>
                <div className="col-md-4">
                    <img
                        className="exch-gift-icon"
                        src={GiftIcon}
                        alt="gift"
                        width={25}
                    />
                    Earn Over <span className="exch-gift-clr">$1,000</span> in
                    Referral Bonuses
                </div>
                <div className="col-md-4">
                    <img
                        className="exch-gift-icon"
                        src={GiftIcon}
                        alt="gift"
                        width={25}
                    />
                    Sign up now to{' '}
                    <span className="exch-gift-clr">claim gifts</span>
                </div>
            </div>
            <div className="row exch-features-parent">
                <div className="col-md-2" />
                <div className="col-md-4 exch-features">
                    <h1 className='exch-heading'>
                        <b>Buy, Trade, Sell, Save & Earn!</b>
                    </h1>
                    <BulletPoints
                        margin={0}
                        bold={true}
                        text={'Lowest Fees in Crypto'}
                    />
                    <BulletPoints bold={true} text={'Over 200+ Tokens'} />
                    <BulletPoints bold={true} text={'No KYC*'} />
                    <BulletPoints
                        bold={true}
                        text={'Buy/Trade/Sell Globally'}
                    />
                    <BulletPoints
                        bold={true}
                        text={'Best Affiliate Program in Industry'}
                    />
                </div>
                <div className="col-md-4">
                    <img
                        className="img-fluid"
                        src={ExchangeFeature}
                        alt="feature"
                        style={{ width: '75%' }}
                    />
                </div>
                <div className="col-md-2" />
            </div>
            <div className="row" style={{ padding: '50px' }}>
                <div className="col-md-12">
                    <h1 className="text-center">
                        <img src={Stocks} alt="stock" width={50} style={{marginTop: "-15px"}} />{' '}
                        <b>Trending Tokens</b>
                    </h1>
                    <div ref={ref} className="mt-50 tradingview-widget-container">
                        <div className="tradingview-widget-container__widget"></div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ paddingBottom: '50px' }}>
                <div className="col-md-12 text-center">
                    <button
                        className="nav-exchange-connect-btn exch-buy"
                        onClick={() => initWidget('buy')}
                    >
                        Buy Now
                    </button>
                </div>
            </div>

            <div className="row exch-security">
                <div className="col-md-12">
                    <h1>
                        <b>Security & Protection</b>
                    </h1>

                    <BulletPoints
                        margin={1}
                        text={
                            'From the inception of Everest the security and protection of vital data has been the prime design consideration. Every decision has been made with the goal of increasing the security of the system.'
                        }
                    />
                    <BulletPoints
                        margin={1}
                        text={
                            'Security is layered throughout the Everest platform to protect the user and transaction data at all times. A distributed system based upon the strongest encryption algorithms, paired with proprietary user-controlled identity storage, and state-of-the-art biometric systems ensure that user data is completely protected.'
                        }
                    />
                    <BulletPoints
                        margin={1}
                        text={
                            'That same care and thought went into the mobile and web applications, the application programming interface (API), and the other data interfaces throughout the platform.'
                        }
                    />
                </div>
            </div>

            <div className="exch-banner-2">
                <div className="text-center">
                    <h1 className="exch-footer-reward">
                        Get Your <span className='exch-footer-amount'>$25</span> Welcome Gift
                    </h1>
                    <button
                        className="nav-exchange-signup-btn exch-banner-btn exch-footer-btn"
                        onClick={() => initWidget('signUp')}
                    >
                        Sign Up
                    </button>
                </div>
            </div>

            <div className="exch-service-wrapper">
                <div className="row exch-service">
                    <div className="col-md-12">
                        <h1 className="text-center">
                            <img src={SwapTrade} alt="stock" width={70} />{' '}
                            <b>Products & Services</b>
                        </h1>
                        <div style={{ padding: '10px', fontSize: '18px' }}>
                            Solutions are built using the financial services
                            toolbox provided by the Everest platform. Those
                            solutions are able to be used to solve the many
                            value transfer, tracking, and delivery challenges
                            faced every day. Below are some example solutions,
                            contact us to tailor a solution for your specific
                            needs.
                        </div>
                    </div>
                </div>
                <div className="row exch-prod-serv">
                    <div className="col-12 text-center">
                        <img src={ProdService} className='prod-serv-image' alt="service" />
                    </div>
                </div>
            </div>

            <div className="exch-banner-footer">
                <div className="mt-50 text-center">
                    <a
                        href="https://twitter.com/EverestDotOrg"
                        target="_blank"
                    >
                        <img
                            className="ref-social-icons"
                            src={Twitter}
                            alt="twitter"
                        />
                    </a>
                    <a
                        href="https://www.discord.com/invite/YQMP4aTYAY"
                        target="_blank"
                    >
                        <img
                            className="ref-social-icons ref-social-margin"
                            src={Discord}
                            alt="discord"
                        />
                    </a>
                    <a
                        href="https://everestdotorg.medium.com/"
                        target="_blank"
                    >
                        <img
                            className="ref-social-icons ref-social-margin"
                            src={Medium}
                            alt="medium"
                        />
                    </a>
                </div>
                <p className="mt-50 exch-footer-text exch-footer-terms">
                    TERMS AND CONDITIONS OF PROMOTION
                </p>
                <p className="exch-footer-text exch-footer-terms exch-footer-sub">
                    *To receive your $25 of Welcome Rewards, enroll in the Everest platform and complete two transactions in 60 days; Buy $1,000 and Trade $1,000 or more to get $25 in rewards.  Welcome Rewards come from a pool of 5,000,000 ID tokens, hurry now to get yours. Referral rewards are earned from a portion of the fees incurred by those individuals who sign up with your Referral code for one year from when they sign up and are paid 60 days after the end of the year.
                </p>
                <p className="exch-footer-text exch-footer-terms exch-footer-sub">
                    **No KYC on purchases under $1,000.
                </p>
            </div>
        </div>
    );
};

export default ExchangeRewards;
